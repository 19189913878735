import React from 'react'
import { Box, Flex, Container, Heading, Text, Image, useColorMode } from '@chakra-ui/react'
import OpenAccountCards from '@/components/Landing/OpenAccountCards'

const Asterisk = './assets/images/asterisk.svg'

interface ISettings {
  id?: string
}

const Settings: React.FC<ISettings> = (props) => {
  const { colorMode } = useColorMode()

  return (
    <Box position="relative" pt={{ base: '40px', lg: '25px' }} {...props}>
      <Container maxW="1280px" alignItems="center">
        <Flex direction={{ base: 'column', lg: 'row' }} alignItems={{ base: 'flex-start', lg: 'center' }}>
          <Heading
            as="h2"
            color={colorMode === 'dark' ? '#fff' : '#000'}
            fontWeight="800"
            fontSize={{ base: '48px', md: '64px', lg: '72px' }}
            lineHeight={{ base: '108%', lg: '100%' }}
            textAlign="left"
            mr="15px"
            mb={{ base: '16px', lg: 0 }}
          >
            <Text display="initial" mr="14px">
              Open your family account in
            </Text>
            <Text bg="#4A63FF" bgClip="text" display="initial">
              2 minutes
            </Text>{' '}
            or less.
          </Heading>
          <Text
            position="relative"
            color={colorMode === 'dark' ? '#929292' : '#444379'}
            fontWeight="500"
            fontSize="16px"
            lineHeight="146%"
            maxW={{ base: '496px', lg: '310px' }}
            textAlign="left"
          >
            No seed phrases or external wallet downloads, jumpstart your family account with your favorite social login.
            <Image
              display={{ base: 'none', lg: 'block' }}
              position="absolute"
              w="14px"
              h="14px"
              top="-17px"
              left="-10px"
              src={Asterisk}
            />
          </Text>
        </Flex>
        <OpenAccountCards />
      </Container>
    </Box>
  )
}

export default Settings
