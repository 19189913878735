import React from 'react'
import { Box, Container, Flex, Heading, useColorMode, Text, Image } from '@chakra-ui/react'
import RunningLine from '@/components/RunningLine'
import * as amplitude from '@amplitude/analytics-browser'
import FeatureCard from '@/components/Landing/Features/FeatureCard'
const SaveTogetherImage = './assets/images/save-together.png'
const SetupFamilyImage = './assets/images/setup-family.png'
const TradeInvestImage = './assets/images/trade-invest.png'

interface IProps {
  id?: string
}

const goToFAQ = () => {
  const faq = document.querySelector('#faq')
  faq?.scrollIntoView({ block: 'start', behavior: 'smooth' })
}

const cards = [
  {
    title: 'Save Together',
    subtitle: "Pool your family's crypto assets into one wallet and invite you family members.",
    type: 'link',
    text: 'Learn more how it works',
    isExternal: false,
    cb: goToFAQ,
    image: {
      src: SaveTogetherImage,
      w: '343px',
      h: '225px',
    },
  },
  {
    title: "Setup family account's rules",
    subtitle:
      'Whether it is Multi Signature, Family DAO, or Equity distribution terms, setup transparent rules that everybody in your family agrees to.',
    type: 'link',
    url: 'https://docs.cryptomarry.me/dissolution-mechanisms',
    text: 'Learn more how equity distribution works',
    isExternal: true,
    image: {
      src: SetupFamilyImage,
      w: '343px',
      h: '225px',
    },
  },
  {
    title: 'Swap, Invest, and Earn through your favorite dApps',
    subtitle: 'Deploy your assets into your favorite DeFi Protocols such as Uniswap, Aave, Lido, and many more...',
    type: 'button',
    text: 'Create Account',
    image: {
      src: TradeInvestImage,
      w: '343px',
      h: '225px',
    },
  },
]

const tempNftSvg = './assets/images/temp-nft.svg'
const nft1 = './assets/images/features/nfts/1.svg'
const nft2 = './assets/images/features/nfts/2.svg'
const nft3 = './assets/images/features/nfts/3.svg'
const nft4 = './assets/images/features/nfts/4.svg'
const nft5 = './assets/images/features/nfts/5.svg'
const nft6 = './assets/images/features/nfts/6.svg'

const NFTsMobile = () => (
  <Flex gap="8px" alignItems={'flex-end'} w="100%">
    <Flex gap="8px" direction={'column'}>
      <Image src={nft2} w={{ base: '115px' }} alt="logo" />
      <Image
        src={nft3}
        w={{ base: '115px' }}
        h={{ base: '50px' }}
        objectFit="cover"
        objectPosition={'0 0'}
        alt="logo"
      />
    </Flex>
    <Flex direction={'column'} gap="8px">
      <Image src={tempNftSvg} w="100%" maxW="238px" h="auto" alt="logo" />
    </Flex>
    <Flex gap="8px" direction={'column'}>
      <Image src={nft4} w={{ base: '115px' }} alt="logo" />
      <Image
        src={nft6}
        w={{ base: '115px' }}
        h={{ base: '50px' }}
        objectFit="cover"
        objectPosition={'0 0'}
        alt="logo"
      />
    </Flex>
  </Flex>
)

const NFTsDesktop = () => (
  <Flex gap="24px" alignItems={'flex-end'}>
    <Flex gap="24px" direction={'column'}>
      <Flex gap="24px" justifyContent={'flex-end'} alignItems={'flex-end'}>
        <Image src={nft1} w={{ base: '80px', lg: '91px' }} h={{ base: '80px', lg: '91px' }} alt="logo" />
        <Image src={nft2} w={{ base: '80px', lg: '195px' }} h={{ base: '80px', lg: '195px' }} alt="logo" />
      </Flex>
      <Image
        src={nft3}
        w={{ base: '80px', lg: '349px' }}
        h={{ base: '80px', lg: '125px' }}
        objectFit="cover"
        objectPosition={'0 0'}
        alt="logo"
      />
    </Flex>
    <Flex>
      <Image src={tempNftSvg} w="100%" maxW="400px" h="auto" alt="logo" />
    </Flex>
    <Flex gap="24px" direction={'column'}>
      <Flex gap="24px" justifyContent={'flex-start'} alignItems={'flex-end'}>
        <Image src={nft4} w={{ base: '80px', lg: '195px' }} h={{ base: '80px', lg: '195px' }} alt="logo" />
        <Image src={nft5} w={{ base: '80px', lg: '91px' }} h={{ base: '80px', lg: '91px' }} alt="logo" />
      </Flex>
      <Image
        src={nft6}
        w={{ base: '80px', lg: '349px' }}
        h={{ base: '80px', lg: '125px' }}
        objectFit="cover"
        objectPosition={'0 0'}
        alt="logo"
      />
    </Flex>
  </Flex>
)

const Features: React.FC<IProps> = (props) => {
  const { colorMode } = useColorMode()

  return (
    <Box position="relative" {...props}>
      <Box w="100%">
        <RunningLine bg="#000" h="50px" fontSize="40px" text="Features" transform="rotate(0)" />
      </Box>
      <Box bg={colorMode === 'dark' ? '#230945' : '#1B1B1B'} pt={{ base: '40px', md: '40px', lg: '56px' }}>
        <Container position="relative" maxW="1280px">
          <Heading
            w={'100%'}
            maxW={{ base: '483px', lg: '100%' }}
            fontWeight="800"
            fontSize={{ base: '48px', lg: '72px' }}
            lineHeight={{ base: '100%', md: '116%' }}
            color="white"
            textAlign="left"
            mb={{ base: '40px', sm: '40px', lg: '80px' }}
          >
            Family Wallet with Superpowers
          </Heading>
          <Flex direction="column" gap="24px" alignItems={{ base: 'center' }} mb="40px">
            {cards.map((card, index) => {
              return FeatureCard({ ...card, key: index })
            })}
          </Flex>
          <Flex
            w="100%"
            direction={'column'}
            justifyContent={'space-between'}
            alignItems={'center'}
            width={'100%'}
            gap={{ base: '24px', lg: '20px' }}
            overflow="hidden"
          >
            <Flex direction={'column'} alignItems={{ base: 'center', lg: 'center' }} maxW="483px" gap="8px">
              <Text color="#fff" fontWeight={'800'} fontSize={'30px'} lineHeight={'116%'} textAlign={'center'}>
                Collect NFTs
              </Text>
              <Text
                color="#fff"
                fontWeight={'500'}
                fontSize={'16px'}
                lineHeight={'146%'}
                textAlign={{ base: 'center', lg: 'center' }}
              >
                Create your Family's gallery of non-fungible tokens (NFTs), crypto collectibles, and other digital
                items.
              </Text>
            </Flex>
            <Box display={{ base: 'none', lg: 'block' }}>
              <NFTsDesktop />
            </Box>
            <Box display={{ base: 'block', lg: 'none' }}>
              <NFTsMobile />
            </Box>
          </Flex>
        </Container>
      </Box>
    </Box>
  )
}

export default Features
