import RunningLine from '@/components/RunningLine'
import {
  FormControl,
  Input,
  FormHelperText,
  Button,
  Flex,
  Image,
  Text,
  Box,
  useDisclosure,
  Spinner,
} from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { emailValidate } from '@/components/Landing/SubscribeForm/validation'
import { SENDPULSE_ADDRESS_BOOK } from '@/configs'
import ModalBasic from '@/components/Dashboard/ModalBasic'
import ButtonGradient from '@/components/Dashboard/ButtonGradient'

const Eclipse = './assets/images/eclipse.svg'

const SubscribeForm = () => {
  const successImage = './assets/images/subscribe-form.svg'
  const [email, setEmail] = useState('')
  const [isValid, setIsValid] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const { isOpen: isOpenSuccess, onOpen: onOpenSuccess, onClose: onCloseSuccess } = useDisclosure()
  const { isOpen: isOpenError, onOpen: onOpenError, onClose: onCloseError } = useDisclosure()

  const handleChange = (event: any) => {
    setEmail(event?.target.value)
  }

  const handleSubmit = async (event: any) => {
    event.preventDefault()
    setIsLoading(true)
    const res = await fetch('/api/sendPulse', {
      body: JSON.stringify({ email, addressBook: SENDPULSE_ADDRESS_BOOK }),
      headers: { 'Content-Type': 'application/json' },
      method: 'POST',
    })
    setIsLoading(false)
    setEmail('')
    if (res.status === 200) {
      onOpenSuccess()
    } else {
      onOpenError()
    }
  }

  useEffect(() => {
    setIsValid(() => emailValidate(email))
  }, [email])

  return (
    <>
      <Box w="100%">
        <RunningLine bg="#000" h="57px" fontSize="40px" text="Features" transform="rotate(0)" />
      </Box>
      <FormControl
        isRequired
        bg="linear-gradient(108.17deg, #C57BFF 0%, #305DFF 100%)"
        px="18px"
        py={{ base: '40px', md: '64px' }}
      >
        <FormHelperText
          display="inline-block"
          fontSize={{ base: '30px' }}
          textAlign="center"
          textColor="#fff"
          fontWeight="800"
          lineHeight="116%"
          mt="0"
          mb="24px"
        >
          Setup meeting with us and get notified about CryptoMarry{' '}
          <Text as="span" position="relative">
            news <Image position="absolute" top="3px" right="-9px" src={Eclipse} w="8px" h="8px" />
          </Text>
        </FormHelperText>
        <Flex direction={{ base: 'column', md: 'row' }} maxW="712px" m="auto" alignItems="center">
          <Input
            type="email"
            border="1px solid #fff"
            minH="60px"
            bg="#fff"
            color="#000"
            placeholder="Your e-mail address"
            value={email}
            onChange={handleChange}
          />
          <Button
            mt={{ base: 4, md: 0 }}
            ml={{ base: 0, md: '8px' }}
            type="submit"
            bg="transparent"
            textColor="#fff"
            fontSize="18px"
            fontWeight="500"
            lineHeight="100%"
            border="1px solid #fff"
            borderRadius="4px"
            w="100%"
            maxW={{ md: '224px' }}
            h={{ base: '48px', md: '60px' }}
            _hover={{ bg: 'rgba(255, 255, 255, 0.1) !important' }}
            onClick={handleSubmit}
            isDisabled={!isValid}
            display="flex"
            alignItems="center"
          >
            <Text>Subscribe </Text>
            {isLoading && (
              <Spinner
                display="inline-block"
                ml={'8px'}
                thickness="2px"
                speed="0.65s"
                emptyColor="gray.200"
                color="blue.500"
                size="sm"
              />
            )}
          </Button>
        </Flex>
      </FormControl>
      <ModalBasic
        onClose={onCloseSuccess}
        isOpen={isOpenSuccess}
        blockScrollOnMount={true}
        isCentered
        maxW="307px"
        padding="10px 24px 24px 24px"
      >
        <Flex direction="column">
          <Image mx="auto" h="122px" w="100%" src={successImage} />
          <Box>
            <Text mt="8px" fontSize="30px" fontWeight="800" lineHeight="116%">
              Great!
            </Text>
            <Text mt="4px" fontSize="16px" fontWeight="400" lineHeight="146%">
              You have successfully subscribed to the news.
            </Text>
          </Box>

          <ButtonGradient mt="38px" w="100%" onClick={onCloseSuccess} text="Continue" />
        </Flex>
      </ModalBasic>
      <ModalBasic
        onClose={onCloseError}
        isOpen={isOpenError}
        blockScrollOnMount={true}
        isCentered
        maxW="307px"
        padding="24px"
      >
        <Flex direction="column">
          <Box>
            <Text mt="8px" fontSize="30px" fontWeight="800" lineHeight="116%">
              Something went wrong
            </Text>
            <Text mt="4px" fontSize="16px" fontWeight="400" lineHeight="146%">
              There were some problems.
              <br />
              Please try again
            </Text>
          </Box>

          <ButtonGradient bg="#D0D0D0" mt="38px" w="100%" onClick={onCloseError} text="Close" />
        </Flex>
      </ModalBasic>
    </>
  )
}

export default SubscribeForm
