import React from 'react'
import { Modal, ModalOverlay, ModalContent, ModalCloseButton, ModalBody, useColorMode, Box } from '@chakra-ui/react'
import { Scrollbars } from 'react-custom-scrollbars-2'

interface IProps {
  onClose: () => void
  isOpen: boolean
  title: any
  children: any
  controls: any
  autoHeightMax: string
  maxW: string
  w: string
  h: string
  padding?: any
  minH?: string
  scrollBehavior?: string
  isCentered?: boolean
}

const ModalBasic: React.FC<IProps> = (props) => {
  const {
    children,
    title,
    controls,
    autoHeightMax = '100vh',
    maxW = '540px',
    w = '100%',
    h = 'auto',
    padding = { base: '20px', md: '40px' },
  } = props

  const { colorMode } = useColorMode()

  return (
    <Modal blockScrollOnMount={false} {...props}>
      <ModalOverlay w="100%" bg="rgba(0, 0, 0, 0.3)" backdropFilter="blur(10px) hue-rotate(90deg)" />
      <ModalContent
        w={w}
        h={h}
        maxW={maxW}
        borderRadius="16px"
        mx="16px"
        bg={colorMode === 'dark' ? '#1F1E20' : '#fff'}
      >
        <ModalCloseButton
          mt={{ base: '0', md: '-48px' }}
          bg={colorMode === 'dark' ? '#1F1E20' : '#fff'}
          mr={{ base: '0', md: '-12px' }}
          _hover={{
            bg: colorMode === 'dark' ? '#1F1E20' : '#fff',
          }}
          _active={{
            bg: colorMode === 'dark' ? '#1F1E20' : '#fff',
          }}
          zIndex="10"
        />
        <ModalBody w={w} p={padding}>
          {title}
          <Scrollbars autoHeight autoHeightMax={autoHeightMax}>
            <Box p="1px">{children}</Box>
          </Scrollbars>
          {controls}
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default ModalBasic
