import type { NextPage } from 'next'
import { Box,Alert,AlertIcon,Text,CloseButton, useDisclosure,Flex } from '@chakra-ui/react'
import Banner from '@/components/Landing/Banner'
import Setting from '@/components/Landing/Settings'
import Awards from '@/components/Landing/Awards'
import Features from '@/components/Landing/Features'
import JoinUs from '@/components/Landing/JoinUs'
import People from '@/components/Landing/People'
import Questions from '@/components/Landing/Questions'
import Reward from '@/components/Landing/Reward'
import Bucket from '@/components/Landing/Bucket'
import Header from '@/components/Landing/Header'
import { useEffect } from 'react'
import useContract from '@/core/useContract'
import useFamilyContract from '@/core/useFamilyContract'
import { useRouter } from 'next/router'
import { IRootState } from '@/store'
import { useSelector } from 'react-redux'
import CompareTable from '@/components/Landing/Table'
import Footer from '@/components/Footer'
import Apps from '@/components/Landing/AppsSection'
import {
  WRONG_PAGE,
  REGISTRATION_PAGE,
  ACCEPT_PROPOSAL_PAGE,
  DASHBOARD_PAGE,
  FAMILY_ONBOARD_PAGE,
  LANDING_PAGE,
} from '@/configs/routes'
import SubscribeForm from '@/components/Landing/SubscribeForm'

const IndexPage: NextPage = () => {
  const { push } = useRouter()
  const { loadContract, isLoaded: isLoadedContract } = useContract()
  const { loadFamilyData, isLoaded: isLoadedFamilyContract } = useFamilyContract()
  const { wrongNetwork, currentAccount } = useSelector((state: IRootState) => state.wallet)
  const { familyStats, proposer } = useSelector((state: IRootState) => state.contract)
  const { marriageContract } = useSelector((state: IRootState) => state.mainContract)

  const redirectTo = (): string => {
    if (wrongNetwork === true) return WRONG_PAGE
    if (marriageContract && wrongNetwork === false && !familyStats) return REGISTRATION_PAGE
    if (familyStats?.ProposalStatus > 0 && familyStats?.ProposalStatus < 4 && familyStats?.hasRole === 11)
      return ACCEPT_PROPOSAL_PAGE
    if (
      (familyStats?.ProposalStatus < 4 && currentAccount?.toUpperCase() === proposer?.toUpperCase()) ||
      (familyStats?.hasRole < 10 && proposer)
    )
      return DASHBOARD_PAGE
    if (familyStats?.hasRole === 10) return FAMILY_ONBOARD_PAGE
    return LANDING_PAGE
  }

  useEffect(() => {
    if (currentAccount) {
      loadContract()
    }
  }, [currentAccount])

  useEffect(() => {
    if (isLoadedContract) {
      loadFamilyData()
    }
  }, [isLoadedContract])

  useEffect(() => {
    if (isLoadedFamilyContract) {
      const page = redirectTo()
      if (page !== LANDING_PAGE) push(page)
    }
  }, [isLoadedFamilyContract])

  const { isOpen: isVisible, onClose } = useDisclosure({
    defaultIsOpen: !localStorage.getItem('crypto-marry-hide-warning'),
  });

  function closeWarning() {
    localStorage.setItem('crypto-marry-hide-warning', true);
    onClose();
  }

  return (
    <>  
      <Header />
      <Box w={'100%'} textAlign="center" fontSize="xl" overflow="hidden" mt="-88px">
        <Banner id="landing-banner" />    
        <Box mt={{ base: '-35px' }}>
          <Awards />
        </Box>
        {isVisible && (
    <Alert status="info" variant='solid'>
          <AlertIcon />
          <Text fontSize={{ base: '24px', md: '24px' }}>
            CryptoMarry v1.1 is in the public Alpha stage and is live on Goerli and Sepolia testnets! Enjoy ❤️
          </Text>
          <CloseButton
            alignSelf="flex-start"
            position="absolute"
            right="8px"
            top="50%"
            transform="translateY(-50%)"
            onClick={closeWarning}
          />
        </Alert>
        )}
        <Box mt={{ base: '35px', md: '90px' }}>
          <Setting />
        </Box>
        <Box mt={{ base: '72px', md: '80px', lg: '109px' }}>
          <Features />
        </Box>
        <Box>
          <SubscribeForm />
        </Box>
        <Box>
          <CompareTable />
        </Box>
        <Box>
          <Apps />
        </Box>
        <Box mt={{ base: '16px', md: '80px', lg: '154px' }}>
          <Reward />
        </Box>
        <Box mt={{ base: '130px', md: '154px' }}>
          <People />
        </Box>
        <Box id="faq" mt={{ base: '72px', md: '80px', lg: '123px' }}>
          <Questions />
        </Box>
        <Box mt={{ base: '72px', md: '100px', lg: '150px' }}>
          <JoinUs />
        </Box>
        <Box>
          <Bucket />
        </Box>
        <Footer />
      </Box>
    </>
  )
}

export default IndexPage
