import { Box, Text, Heading, Flex, Image } from '@chakra-ui/react'

const Valentine = './assets/images/valentine.svg'
const AddCryptoAssets = './assets/images/crypto-assets.svg'
const ManageSwap = './assets/images/manage-swap.png'

const cards = [
  {
    step: '1',
    title: (
      <Heading
        fontWeight="800"
        fontSize={{ base: '30px', md: '30px' }}
        lineHeight="116%"
        color="#fff"
        position="absolute"
        bottom="24px"
        left="24px"
      >
        Invite
        <br />
        your partner
      </Heading>
    ),
    image: Valentine,
    props: {
      w: '203px',
      h: '302px',
      top: '-24px',
      right: '0',
    },
  },
  {
    step: '2',
    title: (
      <Heading
        fontWeight="800"
        fontSize={{ base: '30px', md: '30px' }}
        lineHeight="116%"
        color="#fff"
        position="absolute"
        bottom="24px"
        left="24px"
      >
        Add
        <br />
        crypto assets
      </Heading>
    ),
    image: AddCryptoAssets,
    props: {
      w: '251px',
      h: '257px',
      top: '0',
      right: '0',
    },
  },
  {
    step: '3',
    title: (
      <Heading
        fontWeight="800"
        fontSize={{ base: '30px', md: '30px' }}
        lineHeight="116%"
        color="#fff"
        position="absolute"
        bottom="24px"
        left="24px"
        w="100%"
      >
        Manage, Swap,
        <br />
        and Earn
      </Heading>
    ),
    image: ManageSwap,
    props: {
      w: '204px',
      h: '289px',
      top: '-11px',
      right: '0',
    },
  },
]

const OpenAccountCards: React.FC = () => (
  <Flex wrap="nowrap" gap="16px" mt={{ base: '40px', lg: '60px' }} direction={{ base: 'column', md: 'row' }}>
    {cards?.map((card, index) => (
      <Box
        key={index}
        position="relative"
        textAlign="left"
        bg="linear-gradient(108.17deg, #C57BFF 0%, #305DFF 100%)"
        borderRadius="4px"
        p="24px"
        pt="20px"
        w={{ base: '100%', lg: '33.33%' }}
        minH="270px"
      >
        <Image position="absolute" src={card.image} {...card?.props} />
        <Text fontWeight="500" fontSize={{ base: '16px' }} lineHeight="146%" color="#fff" opacity=".5" mb="8px">
          {card.step} STEP
        </Text>
        {card.title}
      </Box>
    ))}
  </Flex>
)

export default OpenAccountCards
