// Source https://codepen.io/flowrome/pen/abowVRE

import { useEffect, useState } from 'react'
import { ChevronRightIcon, ChevronLeftIcon } from '@chakra-ui/icons'
import { IconButton, Text, Box, Image, Link, useColorMode } from '@chakra-ui/react'

import { useSwipeable } from 'react-swipeable'

const Image1 = './assets/images/carousel/1.jpeg'
const Image2 = './assets/images/carousel/2.jpeg'
const Image3 = './assets/images/carousel/3.png'
const Image4 = './assets/images/carousel/4.jpg'
const TwitterWhite = './assets/images/twitter-white.svg'

const Carousel = () => {
  const { colorMode } = useColorMode()
  const [slides, setSlides] = useState([])
  const slideTotal = slides.length - 1
  let slideCurrent = 0

  function slideToRight() {
    if (!slides.length) return
    if (slideCurrent < slideTotal) {
      slideCurrent++
    } else {
      slideCurrent = 0
    }

    if (slideCurrent > 0) {
      var preactiveSlide = slides[slideCurrent - 1]
    } else {
      var preactiveSlide = slides[slideTotal]
    }
    var activeSlide = slides[slideCurrent]
    if (slideCurrent < slideTotal) {
      var proactiveSlide = slides[slideCurrent + 1]
    } else {
      var proactiveSlide = slides[0]
    }

    slides.forEach((elem) => {
      var thisSlide = elem
      if (thisSlide.classList.contains('preactivede')) {
        thisSlide.classList.remove('preactivede')
        thisSlide.classList.remove('preactive')
        thisSlide.classList.remove('active')
        thisSlide.classList.remove('proactive')
        thisSlide.classList.add('proactivede')
      }
      if (thisSlide.classList.contains('preactive')) {
        thisSlide.classList.remove('preactive')
        thisSlide.classList.remove('active')
        thisSlide.classList.remove('proactive')
        thisSlide.classList.remove('proactivede')
        thisSlide.classList.add('preactivede')
      }
    })
    preactiveSlide.classList.remove('preactivede')
    preactiveSlide.classList.remove('active')
    preactiveSlide.classList.remove('proactive')
    preactiveSlide.classList.remove('proactivede')
    preactiveSlide.classList.add('preactive')

    activeSlide.classList.remove('preactivede')
    activeSlide.classList.remove('preactive')
    activeSlide.classList.remove('proactive')
    activeSlide.classList.remove('proactivede')
    activeSlide.classList.add('active')

    proactiveSlide.classList.remove('preactivede')
    proactiveSlide.classList.remove('preactive')
    proactiveSlide.classList.remove('active')
    proactiveSlide.classList.remove('proactivede')
    proactiveSlide.classList.add('proactive')
  }

  function slideToLeft() {
    if (slideCurrent > 0) {
      slideCurrent--
    } else {
      slideCurrent = slideTotal
    }

    if (slideCurrent < slideTotal) {
      var proactiveSlide = slides[slideCurrent + 1]
    } else {
      var proactiveSlide = slides[0]
    }
    var activeSlide = slides[slideCurrent]
    if (slideCurrent > 0) {
      var preactiveSlide = slides[slideCurrent - 1]
    } else {
      var preactiveSlide = slides[slideTotal]
    }
    slides.forEach((elem) => {
      var thisSlide = elem
      if (thisSlide.classList.contains('proactive')) {
        thisSlide.classList.remove('preactivede')
        thisSlide.classList.remove('preactive')
        thisSlide.classList.remove('active')
        thisSlide.classList.remove('proactive')
        thisSlide.classList.add('proactivede')
      }
      if (thisSlide.classList.contains('proactivede')) {
        thisSlide.classList.remove('preactive')
        thisSlide.classList.remove('active')
        thisSlide.classList.remove('proactive')
        thisSlide.classList.remove('proactivede')
        thisSlide.classList.add('preactivede')
      }
    })

    preactiveSlide.classList.remove('preactivede')
    preactiveSlide.classList.remove('active')
    preactiveSlide.classList.remove('proactive')
    preactiveSlide.classList.remove('proactivede')
    preactiveSlide.classList.add('preactive')

    activeSlide.classList.remove('preactivede')
    activeSlide.classList.remove('preactive')
    activeSlide.classList.remove('proactive')
    activeSlide.classList.remove('proactivede')
    activeSlide.classList.add('active')

    proactiveSlide.classList.remove('preactivede')
    proactiveSlide.classList.remove('preactive')
    proactiveSlide.classList.remove('active')
    proactiveSlide.classList.remove('proactivede')
    proactiveSlide.classList.add('proactive')
  }

  function goToIndexSlide(index) {
    const sliding = slideCurrent > index ? () => slideToRight() : () => slideToLeft()
    while (slideCurrent !== index) {
      sliding()
    }
  }

  function slideInitial() {
    const slides: any = document.querySelectorAll('.carousel-single')
    setSlides(slides)
    setInterval(() => {
      slideToRight()
    }, 10000)
  }

  useEffect(() => {
    if (slides.length) {
      slideToRight()
    }
  }, [slides])

  useEffect(() => {
    slideInitial()
  }, [])

  const handlers = useSwipeable({
    onSwipedLeft: () => slideToRight(),
    onSwipedRight: () => slideToLeft(),
    swipeDuration: 500,
    preventScrollOnSwipe: true,
    trackMouse: true,
  })

  const SLIDES = [
    {
      image: Image1,
      link: 'https://twitter.com/@Irboz',
      text: 'For many people, NFTs are still images of monkeys... Meanwhile, I just formalized my partnership in the form of NFT on the Blockchain 🤓. Web3 is progressing really quickly!',
      author: '@Irboz',
      position: 'DeFi Expert',
    },
    {
      image: Image2,
      link: '',
      text: 'Wow! CryptoMarry should definitely be a part of regular marriage. I can see how partners could feel confident in relationship given their joint Crypto Budget!',
      author: 'Krysta B.',
      position: 'Entrepreneur',
    },
    {
      image: Image3,
      link: '',
      text: 'Hey all. I love the idea of the family DAO. This is something my family has been talking about a LOT-- how to distribute inheritance via smart contracts, etc. My husband and I are married 20 years this year. Maybe we have to get married again... cryptomarried. 🙂',
      author: 'theWholeStory',
      position: 'CM community member',
    },
    {
      image: Image4,
      link: 'https://twitter.com/@AndoCollective',
      text: 'CryptoMarry is brilliant and the Dissolution function is very interesting. Instead of having Dissolution lawyers to mediate and allocate assets, a smart contract will automatically determine this and potentially save huge amounts money.',
      author: '@AndoCollective',
      position: '@_buildspace',
    },
  ]

  return (
    <Box
      className="carousel-container"
      bg={colorMode === 'dark' ? '#1F1E20' : '#fff'}
      px={{ base: '0', md: '28px' }}
      h={{ base: '420px', sm: '370px' }}
    >
      <IconButton
        id="slide-left"
        size="lg"
        aria-label="Prev"
        variant="ghost"
        borderRadius={'100%'}
        icon={<ChevronLeftIcon color="#AEAAAA" fontSize={{ base: '24px', md: '30px' }} />}
        zIndex="10"
        onClick={slideToLeft}
      />

      <Box className="carousel-content" {...handlers}>
        {SLIDES.map((slide, i) => {
          return (
            <Box key={i} className="carousel-single" cursor="move">
              <Box className="carousel-single-image" bg={colorMode === 'dark' ? '#E2E8F0' : '#fff'}>
                <Image
                  w={{ base: '98px', md: '136px' }}
                  h={{ base: '98px', md: '136px' }}
                  className="carousel-single-image-main"
                  src={slide.image}
                  alt={slide.author}
                  objectFit="cover"
                  border={colorMode === 'dark' ? 'solid 8px #1F1E20' : 'solid 8px #fff'}
                />
                {slide.link ? (
                  <Link
                    className="carousel-single-image-icon"
                    p="7px"
                    bg="#55ACEE"
                    w={{ base: '32px', md: '40px' }}
                    h={{ base: '32px', md: '40px' }}
                    borderRadius="100%"
                    border={colorMode === 'dark' ? 'solid 4px #1F1E20' : 'solid 4px #fff'}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    href={slide.link}
                    target="_blank"
                  >
                    <Image src={TwitterWhite} alt="logo" />
                  </Link>
                ) : null}
              </Box>
              <Text color={colorMode === 'dark' ? '#fff' : '#000'} className="carousel-single-description">
                {slide.text}
              </Text>
              <Box className="carousel-single-author">
                <Text className="carousel-single-author-name" textColor="#000 !important">
                  {slide.author}
                </Text>
                <Text className="carousel-single-author-position">{slide.position}</Text>
              </Box>
            </Box>
          )
        })}
      </Box>

      <IconButton
        id="slide-right"
        size="lg"
        fontSize="lg"
        aria-label="Next"
        variant="ghost"
        borderRadius={'100%'}
        icon={<ChevronRightIcon color="#AEAAAA" fontSize={{ base: '24px', md: '30px' }} />}
        zIndex="10"
        onClick={slideToRight}
      />
    </Box>
  )
}

export default Carousel
