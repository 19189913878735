import { Box } from '@chakra-ui/react'

interface Props {
  size?: string | object
  color?: string
  gradient?: boolean
  bold?: boolean
  opacity?: string
}

const Share = ({ size = '24px', color = '#6d6d6d', gradient = false, bold = false, opacity = '0.5' }: Props) => {
  return (
    <Box height={size}>
      <svg height="100%" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g opacity={opacity}>
          <path
            d="M18 10.4989V19.1239C18 19.3701 17.9515 19.6139 17.8573 19.8414C17.7631 20.0689 17.625 20.2756 17.4508 20.4497C17.2767 20.6238 17.07 20.7619 16.8426 20.8562C16.6151 20.9504 16.3713 20.9989 16.125 20.9989H4.87503C4.37775 20.9989 3.90084 20.8014 3.54921 20.4497C3.19757 20.0981 3.00003 19.6212 3.00003 19.1239V7.8739C3.00003 7.37662 3.19757 6.89971 3.54921 6.54808C3.90084 6.19645 4.37775 5.9989 4.87503 5.9989H12.7257"
            stroke={(gradient && 'url(#gradient)') || color}
            strokeWidth={bold ? '2.5' : '1.5'}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M15.7499 2.99963H20.9999V8.24963"
            stroke={(gradient && 'url(#gradient)') || color}
            strokeWidth={bold ? '2.5' : '1.5'}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M10.5 13.4982L20.625 3.37323"
            stroke={(gradient && 'url(#gradient)') || color}
            strokeWidth={bold ? '2.5' : '1.5'}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <defs>
          <linearGradient
            id="gradient"
            x1="6.10352e-05"
            y1="0"
            x2="35.6232"
            y2="15.6557"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.448117" stopColor="#C57BFF" />
            <stop offset="1" stopColor="#305DFF" />
          </linearGradient>
        </defs>
      </svg>
    </Box>
  )
}

export default Share
