import { useState } from 'react'
import { Box, Container, Text, Heading, Flex, Image, Link } from '@chakra-ui/react'

const MDLogoSvg = './assets/images/md-logo.svg'
const MDColorsLogoSvg = './assets/images/md-colors-logo.svg'
const BucketArrowSvg = './assets/images/bucket-arrow.svg'
const LongerBucketArrowSvg = './assets/images/longer-arrow.svg'

const Bucket = () => {
  const [isHover, setIsHover] = useState(false)

  return (
    <Box position="relative" bg="#1C1C1C" py={{ base: '40px', sm: '60px' }}>
      <Container maxW="1280px">
        <Flex justifyContent="flex-start" alignItems={'center'} gap="32px">
          <Heading
            as="h2"
            color={'#fff'}
            fontWeight="800"
            fontSize={{ base: '24px', sm: '30px' }}
            lineHeight="100%"
            textAlign={{ base: 'left', lg: 'center' }}
            display="inline-flex"
            flexWrap="wrap"
            maxW={{ base: '125px', sm: 'none' }}
          >
            <Text display="initial">
              Backed by{' '}
              <Text
                as="span"
                bg="linear-gradient(108.17deg, #C57BFF 44.81%, #305DFF 100%)"
                bgClip="text"
                display="initial"
              >
                the best
              </Text>
            </Text>
          </Heading>
          <Image display={{ base: 'none', md: 'block' }} src={BucketArrowSvg} alt="Arrow right" />
          <Image display={{ base: 'block', md: 'none' }} src={LongerBucketArrowSvg} alt="Arrow right" />
          <Link
            w={'38px'}
            h={'65px'}
            position="relative"
            href="https://maddevs.io/blockchain/?utm_source=cryptomarry"
            target={'_blank'}
            onMouseOver={() => setIsHover(true)}
            onMouseLeave={() => setIsHover(false)}
          >
            <Image
              h="100%"
              position={'absolute'}
              top={'0'}
              left={'0'}
              src={MDColorsLogoSvg}
              alt="Mad Devs"
              opacity={isHover ? '1' : '0'}
              transition="all 0.2s ease"
            />
            <Image
              h="100%"
              position={'absolute'}
              top={'0'}
              left={'0'}
              src={MDLogoSvg}
              alt="Mad Devs"
              opacity={isHover ? '0' : '1'}
              transition="all 0.2s ease"
            />
          </Link>
        </Flex>
      </Container>
    </Box>
  )
}

export default Bucket
