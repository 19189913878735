import { Box, Container, Heading, Text, Link, Flex, Image, useColorMode } from '@chakra-ui/react'
import * as amplitude from '@amplitude/analytics-browser'
import SignIn from '@/components/common/SignIn'

const CheckSvg = './assets/images/check.svg'
const bgDark = './assets/images/bg-dark.png'
const GiftImage = './assets/images/gift-icons.png'

const Item = ({ children, colorMode }) => {
  return (
    <Flex alignItems="flex-start" mb="16px">
      <Image src={CheckSvg} mr="18px" mt="6px" alt="logo" />
      <Box
        fontWeight="500"
        fontSize={{ base: '16px', sm: '20px' }}
        lineHeight="146%"
        color={colorMode === 'dark' ? '#fff' : '#444379'}
      >
        {children}
      </Box>
    </Flex>
  )
}

const Reward = () => {
  const { colorMode } = useColorMode()

  return (
    <Box position="relative" mx="16px">
      <Container
        maxW="1280px"
        alignItems="center"
        bg="linear-gradient(108.17deg, #C57BFF 44.81%, #305DFF 100%)"
        backgroundImage={colorMode === 'dark' ? bgDark : null}
        backgroundPosition={{ base: '15%', lg: '0' }}
        bgRepeat="no-repeat"
        bgSize="cover"
        borderRadius="16px"
        px={{ base: '16px', lg: '69px' }}
        py={{ base: '40px', lg: '56px' }}
        position="relative"
      >
        <Flex direction={{ base: 'column', xl: 'row' }} alignItems={{ base: 'center', xl: 'center' }} gap="48px">
          <Flex
            direction="column"
            alignItems={{ base: 'flex-start', md: 'center', xl: 'flex-start' }}
            textAlign={{ base: 'left' }}
            maxW="512px"
          >
            <Heading
              as="h2"
              fontWeight="800"
              fontSize={{ base: '36px', md: '50px' }}
              lineHeight="108%"
              color="#fff"
              mb={{ base: '8px', md: '16px' }}
            >
              Rewards Program for early CryptoMarry users.
            </Heading>
            <Text
              fontWeight="500"
              fontSize={{ base: '16px', md: '20px' }}
              lineHeight="146%"
              color="#fff"
              mb={{ base: '24px', md: '32px' }}
              maxW={{ base: '320px', md: '507px' }}
            >
              First{' '}
              <Link
                fontWeight="700"
                fontSize={{ base: '16px', md: '20px' }}
                lineHeight="146%"
                color="#fff"
                mb={{ base: '24px', md: '32px' }}
              >
                100 couples
              </Link>{' '}
              will get additional{' '}
              <Link
                fontWeight="700"
                fontSize={{ base: '16px', md: '20px' }}
                lineHeight="146%"
                color="#fff"
                mb={{ base: '24px', md: '32px' }}
              >
                LOVE tokens
              </Link>{' '}
              and{' '}
              <Link
                fontWeight="700"
                fontSize={{ base: '16px', md: '20px' }}
                lineHeight="146%"
                color="#fff"
                mb={{ base: '24px', md: '32px' }}
              >
                NFT badges.
              </Link>{' '}
            </Text>
            <SignIn
              borderRadius="4px"
              h="48px"
              minW={{ base: '100%', md: '468px' }}
              color="white"
              border="1px"
              borderColor="white"
              fontSize="18px"
              className="btn-hover btn-active"
              bg="transparent !important"
              _hover={{ bg: 'rgba(255, 255, 255, 0.1) !important' }}
              text={'Get Started'}
            />
          </Flex>
          <Flex
            minH={{ base: '422px', lg: '476px' }}
            maxW="426px"
            direction="column"
            alignItems="flex-start"
            justifyContent="flex-end"
            bg={colorMode === 'dark' ? '#1F1E20' : '#fff'}
            boxShadow="0px 21.75px 197.5px -47.4px rgba(105, 46, 171, 0.25)"
            borderRadius="16px"
            px={{ base: '24px', sm: '40px' }}
            py={{ base: '24px', sm: '32px' }}
            textAlign="left"
            top="33%"
            right="70px"
            position={{ base: 'initial', xl: 'absolute' }}
            transform={{ base: 'translateY(0)', xl: 'translateY(-50%)' }}
            mt={{ base: '40px', xl: '0' }}
          >
            <Box w="100%" h="100%" position="relative">
              <Image
                w={{ base: '263px', lg: '425px' }}
                h={{ base: '200px', lg: '245px' }}
                position="absolute"
                top="-242px"
                left="50%"
                transform="translateX(-50%)"
                src={GiftImage}
              />
            </Box>
            <Heading
              as="h3"
              fontWeight="800"
              fontSize={{ base: '24px', sm: '30px' }}
              lineHeight="116%"
              color={colorMode === 'dark' ? '#fff' : '#000'}
              mb={{ base: '16px', sm: '21px' }}
            >
              To claim extra 50 LOVE tokens after registering:
            </Heading>
            <Item colorMode={colorMode}>
              Fill out quick survey on your CryptoMarry experience:
              <Link
                bg="#444379"
                bgClip="text"
                ml="6px"
                href="https://twitter.com/@_cryptomarry"
                onClick={() => amplitude.track('promo_twitter_clicked')}
                isExternal
              >
                Survery Link
              </Link>
            </Item>
          </Flex>
        </Flex>
      </Container>
    </Box>
  )
}

export default Reward
