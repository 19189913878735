import { Box, Container, Heading, Flex, Image, Link } from '@chakra-ui/react'
import * as amplitude from '@amplitude/analytics-browser'

const BADGES = [
  {
    img: 'https://api.producthunt.com/widgets/embed-image/v1/top-post-topic-badge.svg?post_id=368746&theme=dark&period=monthly&topic_id=94',
    link: 'https://www.producthunt.com/posts/cryptomarry-2-0?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-cryptomarry',
  },
  {
    img: 'https://api.producthunt.com/widgets/embed-image/v1/top-post-topic-badge.svg?post_id=368746&theme=dark&period=monthly&topic_id=501',
    link: 'https://www.producthunt.com/posts/cryptomarry-2-0?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-cryptomarry',
  },
  {
    img: 'https://api.producthunt.com/widgets/embed-image/v1/top-post-badge.svg?post_id=338812&theme=dark&period=daily',
    link: 'https://www.producthunt.com/posts/cryptomarry-2-0?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-cryptomarry',
  },
]

const Awards = () => {
  return (
    <Box
      position="relative"
      bgSize="cover"
      bg="#000"
      pb={{ base: '64px', md: '69px', lg: '158px' }}
      pt={{ base: '124px', lg: '213px' }}
      outline="1px solid transparent"
    >
      <Container maxW="1280px">
        <Flex direction={'column'} justifyContent="center">
          <Flex
            direction={{ base: 'column', lg: 'row' }}
            alignItems={{ base: 'center', md: 'center' }}
            w="100%"
            mb={{ base: '32px', lg: '50px' }}
          >
            <Heading
              as="h1"
              fontWeight="800"
              fontSize={{ base: '48px', md: '58px', lg: '72px' }}
              lineHeight="108%"
              color="white"
              textAlign={{ base: 'center', md: 'left' }}
              maxW="600px"
              mb={{ base: '24px', lg: '0' }}
            >
              Awards
            </Heading>
            <Flex
              gap={{ base: '16px', md: '50px' }}
              direction={{ base: 'column', md: 'row' }}
              ml={{ base: '0px', lg: '90px' }}
            >
              {BADGES.map((badge, i) => (
                <Link key={i} href={badge.link} target="_blank" onClick={() => amplitude.track('product_hunt_clicked')}>
                  <Image
                    src={badge.img}
                    alt={
                      'CryptoMarry - A&#0032;new&#0032;paradigm&#0032;of&#0032;building&#0032;marriage&#0032;partnerships&#0046; | Product Hunt'
                    }
                    w={'271px'}
                    h={'58px'}
                    mx={{ base: 'auto', md: '0' }}
                  />
                </Link>
              ))}
            </Flex>
          </Flex>
          <Link
            href="https://www.producthunt.com/products/cryptomarry"
            target="_blank"
            onClick={() => amplitude.track('product_hunt_clicked')}
            bg="linear-gradient(108.17deg, #C57BFF 44.81%, #305DFF 100%)"
            bgClip={'text'}
            fontWeight={'500'}
            fontSize={'16px'}
            lineHeight={'146%'}
            display={'flex'}
            m="auto"
            alignItems={'center'}
            gap={'8px'}
          >
            View on Product Hunt
          </Link>
        </Flex>
      </Container>
    </Box>
  )
}

export default Awards
