import React from 'react'
import { Button, Text, VStack } from '@chakra-ui/react'
import ModalBasic from '@/components/Dashboard/ModalBasic'

interface IProps {
  onClose: () => void
  isOpen: boolean
}

const ModalDisclaimer: React.FC<IProps> = (props) => {
  return (
    <ModalBasic {...props}>
      <VStack align="left">
        <Text> Last updated November 11, 2022 </Text>

        <Text>CRYPTOMARRY DISCLAIMER</Text>

        <Text>
          CryptoMarry on https://cryptomarry.me (the APP) is a decentralized peer-to-peer app that people can use to
          create Family Accounts and jointly manage crypto assets. CryptoMarry is in the public Alpha stage. A set of
          smart contracts are deployed on the Ethereum Blockchain. Your use of CryptoMarry involves various risks,
          including, but not limited to, sending funds to wrong addresses, interacting with 3rd party Apps, fluctuation
          of prices of digital tokens, exposing private keys of your presonal wallet and resulting loss of funds. Before
          using CryptoMarry, you should review how the app works. You are responsible for doing your own diligence to
          understand the fees and risks the app presents.
        </Text>
        <Text>
          AS DESCRIBED IN THE TERMS OF SERVICES, THE CRYPTOMARRY IS PROVIDED “AS IS”, AT YOUR OWN RISK, AND WITHOUT
          WARRANTIES OF ANY KIND. Although CryptoMarry developed much of the initial code, the APP is run by smart
          contracts deployed on the Ethereum blockchain. No developer or entity involved in creating the CryptoMarry
          will be liable for any claims or damages whatsoever associated with your use, inability to use, or your
          interaction with other users of , the CryptoMarry, including any direct, indirect, incidental, special,
          exemplary, punitive or consequential damages, or loss of profits, cryptocurrencies, tokens, or anything else
          of value.
        </Text>
        <Text>LANDING WEBSITE DISCLAIMER</Text>
        <Text>
          The information provided by WalletPool LTD (“we,” “us”, or “our”) on the https://cryptomarry.me landing page
          (the “Site”) is for general informational purposes only. All information on the Site is provided in good
          faith, however we make no representation or warranty of any kind, express or implied, regarding the accuracy,
          adequacy, validity, reliability, availability or completeness of any information on the Site. UNDER NO
          CIRCUMSTANCE SHALL WE HAVE ANY LIABILITY TO YOU FOR ANY LOSS OR DAMAGE OF ANY KIND INCURRED AS A RESULT OF THE
          USE OF THE SITE OR RELIANCE ON ANY INFORMATION PROVIDED ON THE SITE. YOUR USE OF THE SITE AND YOUR RELIANCE ON
          ANY INFORMATION ON THE SITE IS SOLELY AT YOUR OWN RISK. EXTERNAL LINKS
        </Text>
        <Text>DISCLAIMER </Text>
        <Text>
          The Site may contain (or you may be sent through the Site) links to other websites or content belonging to or
          originating from third parties or links to websites and features in banners or other advertising. Such
          external links are not investigated, monitored, or checked for accuracy, adequacy, validity, reliability,
          availability or completeness by us. WE DO NOT WARRANT, ENDORSE, GUARANTEE, OR ASSUME RESPONSIBILITY FOR THE
          ACCURACY OR RELIABILITY OF ANY INFORMATION OFFERED BY THIRD-PARTY WEBSITES LINKED THROUGH THE SITE OR ANY
          WEBSITE OR FEATURE LINKED IN ANY BANNER OR OTHER ADVERTISING. WE WILL NOT BE A PARTY TO OR IN ANY WAY BE
          RESPONSIBLE FOR MONITORING ANY TRANSACTION BETWEEN
        </Text>
        <Button onClick={props.onClose}>Close</Button>
      </VStack>
    </ModalBasic>
  )
}

export default ModalDisclaimer
