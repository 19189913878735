import {
  Box,
  Container,
  Heading,
  Text,
  Flex,
  Stack,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Image,
  useColorMode,
} from '@chakra-ui/react'

const UnionSvg = './assets/images/Union.svg'

const ITEMS = [
  {
    title: 'Why do I need CryptoMarry?',
    text: 'CryptoMarry is similar to opening a shared bank account, but is much more cost-efficient and provides a better user experience. Accumulating assets for your kids, programmatically distributing inheritance, investing through 3rd party Apps, swapping crypto, and securing your joint crypto assets are a few things you could do on the CryptoMarry.',
  },
  {
    title: 'Is CryptoMarry wallet self-custodial?',
    text: 'Yes. Only you and your family members will have access to your funds. Once you open an account, a dedicated smart contract will be deployed for your family',
  },
  {
    title: 'How CryptoMarry is different from the existing services?',
    text: 'While similar IRL services could cost you a fortune, the closest similar services in the crypto world are MultiSig wallets. CryptoMarry is different with its social login, sponsored transactions, intuitive DEFI integrations, and most importantly dissolution mechanisms.',
  },
  {
    title: 'What type of crypto assets are supported within the platform?',
    text: 'At this moment, CryptoMarry supports native, ERC20, ERC721 (NFTs), and ERC1155 tokens on the Ethereum Chain.',
  },
  {
    title: 'Does CryptoMarry have MultiSig functionality?',
    text: 'Yes it does. You can setup your threshold number of signatures required to approve transactions.',
  },
  {
    title: 'Can I add Family Members?',
    text: 'Yes. You can invite your family members and involve them in your account. A family member should accept your invitation to join.',
  },
  {
    title: 'I have zero balance in my account?',
    text: 'You and your Family Members can use most of the features of the platform even with zero balance, given you have sufficient funds in your family account to sponsor the transaction fees.',
  },
  {
    title: 'What about Privacy?',
    text: 'All data on the Ethereum Blockchain is not private and immutable, thus not all privacy rights can be exercised. Having said that, there could be privacy by obscurity given that wallet addresses by itself do not reveal owners. All interactions happen 100% on-chain.',
  },
  {
    title: 'What if I changed my mind and do not want to have shared account?',
    text: 'Dissolution procedures can be initiated after agreed period from the date of establishment of your Family Account. If you decide to do so, you can declare dissolution and trigger mechanisms after 10 days if partners did not reach consensus. The mechanisms include splitting native and ERC20 balances between partners upon agreed terms. There is also a mechanism to split NFT assets.',
  },
  {
    title: 'Is there a Demo of the product?',
    text: 'You can explore our limited functionality demo account once you login on the Ethereum and Polygon Mainnet chains. Our product is also available on Goerli testnet, where you can try our product without paying transaction fees',
  },
]

const Questions = () => {
  const { colorMode } = useColorMode()

  return (
    <Box position="relative">
      <Container maxW="1280px">
        <Flex justifyContent="center" mb={{ base: '83px', md: '99px', lg: '80px' }}>
          <Text
            color="#444379"
            fontWeight="bold"
            fontSize="16px"
            lineHeight="146%"
            textAlign="center"
            position="relative"
            maxW={{ base: '180px', sm: '100%' }}
          >
            Any other questions? We have the answers!
            <Image
              src={UnionSvg}
              position="absolute"
              top={{ base: '33px', sm: '10px' }}
              right={{ base: '-23px', sm: '-40px' }}
              display="block"
              alt="logo"
            />
          </Text>
        </Flex>
        <Flex direction="column" alignItems="center" mb={{ base: '24px', lg: '40px' }}>
          <Heading
            as="h2"
            color={colorMode === 'dark' ? '#fff' : '#000'}
            fontWeight="800"
            fontSize={{ base: '48px', md: '64px', lg: '72px' }}
            lineHeight="100%"
            textAlign={{ base: 'left', lg: 'center' }}
            w="100%"
            display="inline-flex"
            flexWrap="wrap"
          >
            <Text display="initial">
              Frequently asked{' '}
              <Text
                as="span"
                bg="linear-gradient(108.17deg, #C57BFF 44.81%, #305DFF 100%)"
                bgClip="text"
                display="initial"
              >
                questions
              </Text>
            </Text>
          </Heading>
        </Flex>
        <Stack direction={'column'} fontSize={{ base: 'md', sm: 'lg', md: 'lg' }} textAlign={'left'} w="100%">
          <Accordion allowToggle>
            <Stack
              px={{ base: '16px', sm: '40px' }}
              pt={{ base: '0', sm: '10px' }}
              pb={{ base: '16px', sm: '20px' }}
              boxShadow="0px 0px 0px 1px rgba(0, 0, 0, 0.05)"
              bg={colorMode === 'dark' ? '#1F1E20' : '#fff'}
              borderRadius="4px"
            >
              {ITEMS.map((item, i) => {
                return (
                  <AccordionItem
                    key={i}
                    borderTop="0"
                    borderBottom={colorMode === 'dark' ? '1px solid #6e7174' : '1px solid #F3EFEF'}
                    mt="0 !important"
                  >
                    <AccordionButton py={{ base: '16px', sm: '24px' }} px="0" _hover={{ bg: 'transparent' }}>
                      <Heading
                        flex="1"
                        as="h3"
                        textAlign="left"
                        color={colorMode === 'dark' ? '#fff' : '#000'}
                        fontWeight="500"
                        fontSize={{ base: '16px', md: '20px' }}
                        lineHeight="146%"
                      >
                        {item.title}
                      </Heading>
                      <AccordionIcon fontSize="25px" color="#AEAAAA" />
                    </AccordionButton>
                    <AccordionPanel
                      color={colorMode === 'dark' ? '#fff' : '#000'}
                      fontWeight="350"
                      fontSize={{ base: '16px' }}
                      lineHeight="25px"
                      px="0"
                      pt="0"
                    >
                      {item.text}
                    </AccordionPanel>
                  </AccordionItem>
                )
              })}
            </Stack>
          </Accordion>
        </Stack>
      </Container>
    </Box>
  )
}

export default Questions
