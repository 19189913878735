import {
  TableContainer,
  Table,
  Thead,
  Tr,
  Th,
  Td,
  Tbody,
  Container,
  Text,
  Box,
  Heading,
  useColorMode,
} from '@chakra-ui/react'
import { CloseIcon, CheckIcon } from '@chakra-ui/icons'

const CompareTable = () => {
  const { colorMode } = useColorMode()

  const tableRows = [
    {
      name: 'Social Sign In',
      cryptoMarry: true,
      multiSigWallets: false,
      softwareWallet: false,
      centralisedExchange: false,
    },
    {
      name: 'Multi-Signature',
      cryptoMarry: true,
      multiSigWallets: true,
      softwareWallet: false,
      centralisedExchange: false,
    },
    {
      name: 'Defi Integrations',
      cryptoMarry: true,
      multiSigWallets: null,
      softwareWallet: null,
      centralisedExchange: false,
    },
    {
      name: 'Sponsored Transactions',
      cryptoMarry: true,
      multiSigWallets: false,
      softwareWallet: false,
      centralisedExchange: false,
    },
    {
      name: 'DAO Enabled',
      cryptoMarry: true,
      multiSigWallets: null,
      softwareWallet: false,
      centralisedExchange: false,
    },
    {
      name: 'Equity Distribution',
      cryptoMarry: true,
      multiSigWallets: false,
      softwareWallet: false,
      centralisedExchange: false,
    },
    {
      name: 'Open sourced contracts',
      cryptoMarry: true,
      multiSigWallets: true,
      softwareWallet: null,
      centralisedExchange: false,
    },
    { name: 'Privacy', cryptoMarry: true, multiSigWallets: true, softwareWallet: null, centralisedExchange: false },
  ]

  const buildTableCell = (prop: boolean | null) => {
    return (
      <Td textAlign="center">
        {prop === null ? (
          <CheckIcon boxSize={4} textColor="#929292" />
        ) : prop ? (
          <CheckIcon boxSize={4} color="#16B926" />
        ) : (
          <CloseIcon boxSize={4} color="#ED3232" />
        )}
      </Td>
    )
  }

  return (
    <Container maxW="1280px">
      <Box pt={{ base: '58px', md: '80px' }} pb="80px">
        <Heading
          as="h2"
          color={colorMode === 'dark' ? '#fff' : '#000'}
          fontWeight="800"
          fontSize={{ base: '48px', md: '64px', lg: '72px' }}
          lineHeight={{ base: '108%', lg: '100%' }}
          textAlign="left"
          mr="15px"
          mb={{ base: '16px', lg: '32px' }}
        >
          <Text display="initial" mr="14px">
            Compare
          </Text>
          <Text bg="#4A63FF" bgClip="text" display="initial">
            Solutions
          </Text>{' '}
        </Heading>
        <Box>
          <TableContainer
            whiteSpace="wrap"
            textAlign="center"
            overflowX="auto"
            border="1px solid #DFDFDF"
            borderRadius="4px"
          >
            <Table>
              <Thead>
                <Tr>
                  <Th position="sticky" left="0" top="0" zIndex="5" />
                  <Th
                    textAlign="center"
                    zIndex="3"
                    textColor={colorMode === 'dark' ? '#fff' : '#000'}
                    fontSize="16px"
                    fontWeight="700"
                    lineHeight="146%"
                  >
                    CryptoMarry
                  </Th>
                  <Th
                    textAlign="center"
                    zIndex="3"
                    textColor={colorMode === 'dark' ? '#fff' : '#000'}
                    fontSize="16px"
                    fontWeight="700"
                    lineHeight="146%"
                  >
                    MultiSig Wallets
                  </Th>
                  <Th
                    textAlign="center"
                    textColor={colorMode === 'dark' ? '#fff' : '#000'}
                    fontSize="16px"
                    fontWeight="700"
                    lineHeight="146%"
                  >
                    Software Wallet ("EOA")
                  </Th>
                  <Th
                    textAlign="center"
                    textColor={colorMode === 'dark' ? '#fff' : '#000'}
                    fontSize="16px"
                    fontWeight="700"
                    lineHeight="146%"
                  >
                    Centralised exchange
                  </Th>
                </Tr>
              </Thead>
              <Tbody>
                {tableRows.map(({ name, cryptoMarry, multiSigWallets, softwareWallet, centralisedExchange }, index) => (
                  <Tr key={`${index}-${name}`} py="24px">
                    <Td
                      position="sticky"
                      left="0"
                      top="0"
                      zIndex="5"
                      fontSize="20px"
                      color={colorMode === 'dark' ? '#fff' : '#000'}
                      fontWeight="400"
                      lineHeight={{ base: '120%', md: '146%' }}
                    >
                      {name}
                    </Td>
                    {buildTableCell(cryptoMarry)}
                    {buildTableCell(multiSigWallets)}
                    {buildTableCell(softwareWallet)}
                    {buildTableCell(centralisedExchange)}
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
    </Container>
  )
}

export default CompareTable
