import { Button } from '@chakra-ui/react'

interface IProps {
  text: string
  onClick: () => void
  isDisabled?: boolean
  size?: string
}

const ButtonGradient = (props: IProps) => {
  const { text, onClick, isDisabled } = props
  return (
    <Button
      mr="8px"
      bgGradient="linear-gradient(108.17deg, #C57BFF 44.81%, #305DFF 100%)"
      color="#fff"
      colorScheme="linear-gradient(108.17deg, #C57BFF 44.81%, #305DFF 100%)"
      _hover={{
        bg: 'linear-gradient(108.17deg, #C57BFF 0, #305DFF 0)',
      }}
      _active={{
        bg: 'linear-gradient(108.17deg, #C57BFF 0, #1849f7 0)',
      }}
      isDisabled={isDisabled}
      onClick={onClick}
      {...props}
    >
      {text}
    </Button>
  )
}

export default ButtonGradient
