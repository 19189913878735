import { Box, Heading, Flex, Text, Container, useColorMode } from '@chakra-ui/react'
import Carousel from '@/components/Landing/Carousel'

const People = () => {
  const { colorMode } = useColorMode()

  return (
    <Box position="relative">
      <Container maxW="1280px">
        <Flex direction="column" alignItems="center" mt="65px" mb={{ base: '24px', md: '32px' }}>
          <Heading
            as="h2"
            color={colorMode === 'dark' ? '#fff' : '#000'}
            fontWeight="800"
            fontSize={{ base: '48px', md: '64px', lg: '72px' }}
            lineHeight="100%"
            textAlign="center"
            mb={{ base: '16px', md: '8px' }}
            maxW={{ base: '200px', md: '100%' }}
            display="flex"
            flexWrap="wrap"
            justifyContent="center"
          >
            <Text display="initial">
              People{' '}
              <Text
                as="span"
                bg="linear-gradient(108.17deg, #C57BFF 44.81%, #305DFF 100%)"
                bgClip="text"
                display="initial"
                textTransform="uppercase"
                whiteSpace="nowrap"
              >
                love us
              </Text>
            </Text>
          </Heading>
          <Text
            color={colorMode === 'dark' ? '#fff' : '#000'}
            fontWeight="800"
            fontSize={{ base: '24px', md: '30px' }}
            lineHeight="116%"
            textAlign="center"
            mb="16px"
          >
            You're in good company
          </Text>
          <Text
            color={colorMode === 'dark' ? '#fff' : '#000'}
            fontWeight="500"
            fontSize={{ base: '16px', md: '20px' }}
            lineHeight="146%"
            textAlign="center"
            display="inline"
          >
            See why{' '}
            <Text as="span" fontWeight="700" mr="6px">
              over 100+ users
            </Text>{' '}
            are already willing to use CryptoMarry!
          </Text>
        </Flex>
        <Carousel />
      </Container>
    </Box>
  )
}

export default People
