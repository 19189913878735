import { Box, Text, useColorMode } from '@chakra-ui/react'

interface IProps {
  text: string
}

const RunningLine = (props: IProps) => {
  const { text } = props
  const { colorMode } = useColorMode()

  return (
    <Box
      className="running-line"
      bg={colorMode === 'light' ? '#1B1B1B' : '#161616'}
      h={{ base: '58px', md: '78px' }}
      fontSize={{ base: '32px', md: '64px' }}
      transform={{
        base: 'rotate(4deg)',
        '2xl': 'rotate(2deg)',
        '3xl': 'rotate(1deg)',
      }}
      {...props}
    >
      <Text
        lineHeight="114%"
        fontSize={'inherit'}
        color={colorMode === 'light' ? '#6D6D6D' : '#444379'}
        textTransform="uppercase"
        fontWeight="normal"
        className="running-line_text"
      >
        {[...Array(20)].map((x, i) => (
          <span key={i}>{text} ∙</span>
        ))}
      </Text>
    </Box>
  )
}

export default RunningLine
