import { Heading, Link, Text, Card, Image, CardBody, Stack, Divider, CardFooter, Box, Flex } from '@chakra-ui/react'
import { ChevronDownIcon } from '@chakra-ui/icons'
import ShareIcon from '@/components/Icons/Share'
import SignIn from '@/components/common/SignIn'

interface IProps {
  title?: string
  subtitle?: string
  type?: string
  text?: string
  isExternal?: boolean
  url?: string
  cb?: () => void
  key: number
  image: any
}

const FeatureCard: React.FC<IProps> = (props: IProps) => {
  return (
    <Card
      direction={{ base: 'column', md: 'row-reverse' }}
      justifyContent="space-between"
      alignItems="stretch"
      overflow="hidden"
      key={props.key}
      w="100%"
      borderRadius="4px"
      bg="#292929"
      textColor="#fff"
      minH="318px"
    >
      <Image src={props.image.src} w={{ base: '100%', md: props.image.w, lg: '542px' }} />
      <Stack justifyContent="space-between" maxW="510px">
        <CardBody>
          <Heading fontWeight="800" mb="16px" fontSize={{ base: '30px' }} lineHeight="116%" textAlign="left">
            {props?.title || ''}
          </Heading>
          <Text fontSize={{ base: '16px' }} fontWeight="400" lineHeight="146%" textAlign="left">
            {props?.subtitle || ''}
          </Text>
        </CardBody>
        <Divider borderColor="#929292" display={{ base: 'block', sm: 'none' }} />

        {props?.type && (
          <CardFooter>
            {props?.type === 'link' ? (
              <Link
                href={props.url}
                onClick={() => (props?.cb ? props.cb() : null)}
                textColor="#C57BFF"
                isExternal={props.isExternal}
                _hover={{ textDecoration: 'none' }}
                display="inline-flex"
                alignItems="center"
                gap="11px"
              >
                {props?.text}{' '}
                {props.isExternal ? (
                  <ShareIcon opacity="1" size="24px" color="#C57BFF" />
                ) : (
                  <ChevronDownIcon w="30px" h="30px" />
                )}
              </Link>
            ) : props?.type === 'button' ? (
              <SignIn
                borderRadius="4px"
                className="btn-hover btn-active"
                _hover={{ bg: 'rgba(255, 255, 255, 0.1) !important' }}
                _active={{ bg: 'rgba(255, 255, 255, 0.1) !important' }}
                minW={{ base: '88px', lg: '180px' }}
                h={{ base: '32px', lg: '48px' }}
                text={props?.text}
                border="1px solid #fff"
                bg="transparent"
                fontSize="24px"
                lineHeight="146%"
                fontWeight="500"
                textColor="#fff"
                w={{ base: '100%', md: '470px' }}
                maxW={{ base: '100%', md: '295px', lg: '469px' }}
                minH="70px"
              />
            ) : (
              ''
            )}
          </CardFooter>
        )}
      </Stack>
    </Card>
  )
}

export default FeatureCard
