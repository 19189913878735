import { Box, Flex, useColorMode, Container, Text, Heading } from '@chakra-ui/react'
import RunningLine from '@/components/RunningLine'
import JoinUsCard from './JoinUsCard'

import { DISCORD_LINK, TWITTER_LINK, GITHUB_LINK } from '@/configs'

const discord = './assets/images/discord-black.svg'
const twitter = './assets/images/twitter.svg'
const github = './assets/images/github.svg'
const bgDark = './assets/images/bg-dark.png'

const CARDS = [
  {
    title: 'Discord',
    icon: discord,
    description: 'Join CryptoMarry community',
    link: DISCORD_LINK,
    width: '30%',
    isDisabled: false,
  },
  {
    title: 'Twitter',
    icon: twitter,
    description: 'All the latest news and updates from CryptoMarry',
    link: TWITTER_LINK,
    width: '40%',
    isDisabled: false,
  },
  {
    title: 'GitHub',
    icon: github,
    description: 'Explore open sourced CM contract codebase',
    link: GITHUB_LINK,
    width: '30%',
    isDisabled: false,
  },
]

const JoinUs = () => {
  const { colorMode } = useColorMode()

  return (
    <Box position="relative">
      <Box position="absolute" top="0" w="100%" zIndex="3">
        <RunningLine text="Joint crypto account ∙ happy family" transform="rotate(-1.74deg)" />
      </Box>
      <Flex
        minH="300px"
        bg="linear-gradient(108.17deg, #C57BFF 44.81%, #305DFF 100%)"
        backgroundImage={colorMode === 'dark' ? bgDark : null}
        backgroundPosition={{ base: '15%', lg: '0' }}
        bgRepeat="no-repeat"
        bgSize="cover"
        clipPath={{
          base: 'polygon(0 1.7%, 100% 0%, 100% 100%, 0% 100%)',
          md: 'polygon(0 3.5%, 100% 0%, 100% 100%, 0 100%)',
          lg: 'polygon(0 3.5%, 100% 0%, 100% 100%, 0 100%)',
          xl: 'polygon(0 15%, 100% -5%, 100% 100%, 0 100%)',
        }}
        zIndex="1"
        direction="column"
        alignItems="center"
        pb={{ base: '32px', md: '0' }}
      >
        <Container maxW="1280px" mt="48px" pt={{ base: '70px', md: '120px' }} alignItems="center">
          <Flex direction="column" alignItems="center" mb="35px">
            <Heading
              as="h2"
              fontWeight="800"
              fontSize={{ base: '48px', md: '64px', lg: '72px' }}
              lineHeight="116%"
              color="#fff"
              mb={{ base: '8px', md: '16px', lg: '8px' }}
            >
              Join Us
            </Heading>
            <Text maxW="505px" fontWeight="500" fontSize={{ base: '16px', md: '20px' }} lineHeight="146%" color="#fff">
              We want to deliver the best experience for our users. Join our community and let us know what you LOVE in
              CryptoMarry.
            </Text>
          </Flex>
          <Flex
            position="relative"
            w="100%"
            justifyContent="space-between"
            direction={{ base: 'column', md: 'row' }}
            gap={{ base: '8px', md: '13px', lg: '23px' }}
            className="join-us_cards"
          >
            {CARDS.map((card, i) => {
              return (
                <JoinUsCard
                  className="join-us_cards-card"
                  key={i}
                  width={{ base: '100%', md: card.width }}
                  title={card.title}
                  description={card.description}
                  image={card.icon}
                  link={card.link}
                  isDisabled={card.isDisabled}
                />
              )
            })}
          </Flex>
        </Container>
      </Flex>
    </Box>
  )
}

export default JoinUs
