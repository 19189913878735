import { Flex, Text, Heading, Image, Button } from '@chakra-ui/react'
import { ChevronRightIcon } from '@chakra-ui/icons'
import * as amplitude from '@amplitude/analytics-browser'

const JoinUsCard = ({ title, description, width, image, className, link, isDisabled }) => {
  const eventProperties = {
    where: title,
  }
  return (
    <Flex
      as="a"
      target="_blank"
      href={link}
      onClick={() => amplitude.track('joinus_clicked', eventProperties)}
      direction={{ base: 'row', md: 'column' }}
      alignItems={{ base: 'flex-start', md: 'center' }}
      boxShadow="0px 98.75px 197.5px rgba(105, 46, 171, 0.25)"
      bg="#fff"
      w={width}
      borderTopLeftRadius="16px"
      borderTopRightRadius="16px"
      pb={{ base: '30px' }}
      px={{ base: '24px', md: '16px' }}
      pt={{ base: '30px', md: '0' }}
      className={className}
      textDecor="none"
    >
      <Flex
        w={{ base: 'auto', md: '82px' }}
        h={{ base: 'auto', md: '82px' }}
        borderRadius="100%"
        mt={{ base: '2px', md: '-41px' }}
        mr={{ base: '21px', md: '0' }}
        bg="#fff"
        alignItems="center"
        justifyContent="center"
      >
        <Image src={image} h={{ base: '24px', md: '35px' }} minW={{ base: '24px', md: 'auto' }} alt="logo" />
      </Flex>
      <Flex direction="column" textAlign={{ base: 'left', md: 'center' }} mb={{ base: '0', md: '8px' }}>
        <Heading
          as="h3"
          fontWeight="800"
          fontSize={{ base: '24px', md: '30px' }}
          lineHeight="116%"
          color="#000"
          mb="8px"
        >
          {title}
        </Heading>
        <Text fontWeight="400" fontSize="17px" lineHeight="146%" color="#000" mb="0">
          {description}
        </Text>
      </Flex>
      <ChevronRightIcon
        color="#AEAAAA"
        fontSize={{ base: '24px', md: '30px' }}
        my="auto"
        ml="auto"
        pl="px"
        display={{ base: 'block', md: 'none' }}
      />
      <Button
        px="36px"
        py="15px"
        fontWeight="800"
        fontSize="18px"
        lineHeight="100%"
        border="1px solid transparent"
        borderRadius="20px"
        bg="linear-gradient(to right, white, white), linear-gradient(to right, #C57BFF, #305DFF)"
        bgClip="padding-box, border-box"
        _hover={{
          bg: 'linear-gradient(to right, white, white), linear-gradient(to right, #C57BFF, #305DFF)',
          bgClip: 'padding-box, border-box',
        }}
        _active={{
          bg: 'linear-gradient(to right, white, white), linear-gradient(to right, #C57BFF, #305DFF)',
          bgClip: 'padding-box, border-box',
        }}
        display={{ base: 'none', md: 'flex' }}
        isDisabled={isDisabled}
      >
        <Text bg="linear-gradient(108.17deg, #C57BFF 44.81%, #305DFF 100%)" bgClip="text">
          Join
        </Text>
      </Button>
    </Flex>
  )
}

export default JoinUsCard
