import React from 'react'
import { Box, Container, Flex, Heading, Text, Image, useColorMode } from '@chakra-ui/react'
import RunningLine from '@/components/RunningLine'
import SignIn from '@/components/common/SignIn'
import * as amplitude from '@amplitude/analytics-browser'

const bgDark = './assets/images/bg-dark.png'
const windowsGroup = './assets/images/bannerHead.svg'
const windowsGroupSmall = './assets/images/windows-group-small.svg'

interface IProps {
  id?: string
}

const Banner: React.FC<IProps> = (props) => {
  const { colorMode } = useColorMode()

  // Track events with optional properties
  const eventProperties = {
    connectWalletClicked: 'main',
  }

  return (
    <Box position="relative" zIndex={5} {...props}>
      <Box
        position="relative"
        minH={{ base: '1000px', sm: '1060px', md: '930px', lg: '1165px', xl: '730px' }}
        bg="linear-gradient(108.17deg, #C57BFF 44.81%, #305DFF 100%)"
        backgroundImage={colorMode === 'dark' ? bgDark : null}
        backgroundPosition={{ base: '15%', lg: '0' }}
        bgRepeat="no-repeat"
        bgSize="cover"
        clipPath={{
          base: 'polygon(0% 0%, 100% 0%, 100% 98.5%, 0% 100%)',
          md: 'polygon(0% 0%, 100% 0%, 100% 97.4%, 0% 100%)',
          lg: 'polygon(0% 0%, 100% 0%, 100% 94.3%, 0% 100%)',
          xl: 'polygon(0% 0%, 100% 0%, 100% 92.1%, 0% 100%)',
        }}
        pt={{ base: '100px', md: '157px' }}
        pb={{ base: '100px' }}
      >
        <Container maxW="1280px">
          <Flex direction={{ base: 'column', xl: 'row' }} alignItems={{ base: 'center', xl: 'flex-start' }}>
            <Flex w="100%" direction="column" alignItems={{ base: 'flex-start', sm: 'center', xl: 'flex-start' }}>
              <Heading
                as="h1"
                fontWeight="800"
                fontSize={{ base: '48px', md: '52px', lg: '72px' }}
                lineHeight={{ base: '100%', md: '116%' }}
                color="white"
                textAlign={{ base: 'left', sm: 'center', xl: 'left' }}
                mb={{ base: '24px', md: '16px' }}
                maxW="600px"
              >
                Crypto savings & earnings made simple for couples
              </Heading>
              <Text
                as="p"
                fontSize="16px"
                fontWeight="500"
                lineHeight="146%"
                color="white"
                textAlign={{ base: 'left', sm: 'center', xl: 'left' }}
                maxW="500px"
                mb="32px"
              >
                Pool your crypto assets into one family wallet, save, and invest together through top DeFi platforms,
                all while keeping your assets safe and secure.
              </Text>
              <SignIn
                borderRadius="4px"
                py={{ base: '15px' }}
                px={{ base: '19px' }}
                color="white"
                border="1px"
                borderColor="white"
                fontSize="18px"
                lineHeight="100%"
                fontWeight="500"
                className="btn-hover btn-active"
                bg="transparent !important"
                _hover={{ bg: 'rgba(255, 255, 255, 0.1) !important' }}
                text={'Get Started'}
                w={{ base: '100%' }}
                maxW="400px"
                marginX={{ md: 'left' }}
                minH="60px"
              />
            </Flex>
            <Flex position="relative" mt={{ base: '30px', lg: '0' }} w={{ base: '286px', sm: '406px', lg: '453px' }}>
              <Image
                src={windowsGroup}
                w={{ base: '100%', md: '752px', lg: '968px' }}
                display={{ base: 'none', md: 'block' }}
                maxW={{ md: '752px', lg: '968px' }}
                alt="Banner Phone"
                position="absolute"
                right={{ base: '50px', sm: '0', md: '50%' }}
                transform={{ md: 'translateX(50%)' }}
                top="27px"
              />
              <Image
                position="absolute"
                top="0"
                left="50%"
                transform="translateX(-50%)"
                w="100%"
                display={{ base: 'block', md: 'none' }}
                src={windowsGroupSmall}
                mx="auto"
              />
            </Flex>
          </Flex>
        </Container>
      </Box>
      <Box fontSize="40px" position="absolute" bottom={{ base: '-45px', lg: '-13px' }} w="100%">
        <RunningLine text="Joint crypto account ∙ happy family" transform="rotate(-1.74deg)" />
      </Box>
    </Box>
  )
}

export default Banner
