import { ReactNode } from 'react'
import { Box, Container, Link, Flex, SimpleGrid, Stack, Text, Button, useDisclosure } from '@chakra-ui/react'
import Logo from '@/components/Logo'
import ModalDisclaimer from '@/components/Dashboard/Modals/ModalDisclaimer'
import {
  DISCORD_LINK,
  TWITTER_LINK,
  GITHUB_LINK,
  USER_GUIDE_LINK,
  PRICING_LINK,
  DEPLOYED_CONTRACTS_LINK,
  V1_LINK,
} from '@/configs'
import { TOS_PAGE, PRIVACY_PAGE } from '@/configs/routes'
import * as amplitude from '@amplitude/analytics-browser'
import SignIn from '@/components/common/SignIn'

const ListHeader = ({ children }: { children: ReactNode }) => {
  return (
    <Text textColor="#fff" fontWeight={'500'} fontSize={'lg'} mb={2}>
      {children}
    </Text>
  )
}

const handleClick = (param: string) => {
  amplitude.track(param)
}

export interface IFooter {
  name: string
  link: string
  isExternal?: boolean
  track?: string
}

const products: IFooter[] = [
  { name: 'Get Started', link: '#', isExternal: true },
  { name: 'Pricing', link: PRICING_LINK, isExternal: true },
  { name: 'Deployed Contracts', link: DEPLOYED_CONTRACTS_LINK, isExternal: true },
  { name: 'CryptoMarry 1.0', link: V1_LINK, isExternal: true },
]

const resources: IFooter[] = [
  { name: 'Guide', link: USER_GUIDE_LINK, isExternal: true },
  { name: 'Terms', link: TOS_PAGE, isExternal: true },
  { name: 'Privacy Policy', link: PRIVACY_PAGE, isExternal: true },
]

const company: IFooter[] = [
  { name: 'About', link: '#', isExternal: true },
  { name: 'Blog', link: '#', isExternal: true },
  { name: 'Contact Us', link: TWITTER_LINK, isExternal: true },
]

const socialNetworks: IFooter[] = [
  { name: 'Twitter', link: TWITTER_LINK },
  { name: 'Discord', link: DISCORD_LINK },
  { name: 'GitHub', link: GITHUB_LINK },
]

const Footer = () => {
  const { isOpen: isOpenDisclaimer, onOpen: onOpenDisclaimer, onClose: onCloseDisclaimer } = useDisclosure()

  const handleOpenDisclaimer = () => {
    amplitude.track('disclaimer_open_clicked')
    onOpenDisclaimer()
  }

  return (
    <Box bg={'#161616'} width={'100%'} color="#929292">
      <Container as={Stack} maxW="1280px" pt="40px" pb="80px">
        <Flex gap="37px" justifyContent="space-between" direction={{ base: 'column', md: 'row' }}>
          <Link href={'/'} _hover={{ textDecoration: 'none' }} textAlign="left" display="inline-block">
            <Logo display="block" isWhite={true} />
          </Link>
          <SimpleGrid
            w={{ base: '100%', md: '70%' }}
            textAlign="left"
            templateColumns={{ base: '1fr 1fr', md: '1fr 1fr 1fr 1fr' }}
            spacing={8}
          >
            <Stack align={'flex-start'}>
              <ListHeader>Product</ListHeader>
              {products.map((product, index) => (
                <Link
                  key={`${product.name}-${index}`}
                  href={product.link}
                  fontSize="16px"
                  lineHeight="146%"
                  fontWeight="400"
                  isExternal={product.isExternal}
                  onClick={() => (product.track ? handleClick(product.track) : null)}
                >
                  {product.name}
                </Link>
              ))}
              <SignIn
                className="btn-hover btn-active"
                color="#929292"
                fontSize="16px"
                lineHeight="146%"
                fontWeight="400"
                variant="link"
                text={'Log in'}
              />
            </Stack>
            <Stack align="flex-start">
              <ListHeader>Resources</ListHeader>
              {resources.map((resource, index) => (
                <Link
                  key={`${resource.name}-${index}`}
                  href={resource.link}
                  fontSize="16px"
                  lineHeight="146%"
                  fontWeight="400"
                  onClick={() => (resource.track ? handleClick(resource.track) : null)}
                  isExternal={resource.isExternal}
                >
                  {resource.name}
                </Link>
              ))}
              <Button
                onClick={handleOpenDisclaimer}
                fontWeight="400"
                fontSize="16px"
                lineHeight="146%"
                color="#929292"
                variant="link"
              >
                CryptoMarry Disclaimer
              </Button>
            </Stack>
            <Stack align={'flex-start'}>
              <ListHeader>Company</ListHeader>
              {company.map((item, index) => (
                <Link
                  key={`${item.name}-${index}`}
                  href={item.link}
                  fontSize="16px"
                  lineHeight="146%"
                  fontWeight="400"
                  isExternal={item.isExternal}
                  onClick={() => (item.track ? handleClick(item.track) : null)}
                >
                  {item.name}
                </Link>
              ))}
            </Stack>
            <Stack align={'flex-start'}>
              <ListHeader>Follow Us</ListHeader>
              {socialNetworks.map((social, index) => (
                <Link
                  key={`${social.name}-${index}`}
                  href={social.link}
                  fontSize="16px"
                  lineHeight="146%"
                  fontWeight="400"
                  isExternal
                  onClick={() => handleClick('footer_social_clicked')}
                >
                  {social.name}
                </Link>
              ))}
            </Stack>
          </SimpleGrid>
        </Flex>
      </Container>
      <ModalDisclaimer onClose={onCloseDisclaimer} isOpen={isOpenDisclaimer} />
    </Box>
  )
}

export default Footer
