import { Text, Box, Flex, Container, Image, useColorMode } from '@chakra-ui/react'

const Uniswap = './assets/images/uniswap-uni.svg'
const Maker = './assets/images/Maker-icon.svg'
const Curve = './assets/images/curve.png'
const Aave = './assets/images/aave.svg'
const Compound = './assets/images/compound.svg'
// const AwardArrow = './assets/images/award-arrow.svg'
import SignIn from '@/components/common/SignIn'
import React from 'react'

const cards = [
  {
    title: 'Uniswap',
    subtitle: 'Swap, earn, and build on the leading decentralized crypto trading protocol.',
    image: Uniswap,
    imageBackground: '#FF007A',
    alt: 'Uniswap',
  },
  {
    title: 'Maker',
    subtitle:
      "MakerDAO enables the generation of Dai, the world's first unbiased currency and leading decentralized stablecoin.",
    image: Maker,
    imageBackground: '#1AAB9B',
    alt: 'Maker',
  },
  {
    title: 'Curve',
    subtitle: 'Curve is DeFis leading AMM, (Automated Market Maker). ',
    image: Curve,
    imageBackground: '#290D56',
    alt: 'Curve',
  },
  {
    title: 'Aave',
    subtitle: 'Earn interest, borrow assets, and build applications with Aave Protocol',
    image: Aave,
    imageBackground: '#3DAFC2',
    alt: 'Aave',
  },
  {
    title: 'Compound III',
    subtitle: 'Earn interested on your stable coins.',
    image: Compound,
    imageBackground: '#00D395',
    alt: 'Compound III',
  },
]

const Apps: React.FC = () => {
  const { colorMode } = useColorMode()
  return (
    <Container maxW="1280px">
      <Box>
        <Text
          fontSize="48px"
          lineHeight="108%"
          fontWeight="800"
          textColor={colorMode === 'dark' ? '#fff' : '#000'}
          textAlign="left"
          mb="48px"
        >
          Want to connect with an app?
        </Text>
        <Flex flexWrap="wrap" alignItems="flex-start">
          {cards.map((card, index) => (
            <Box key={`${index}-${card.title}`} w={{ base: '100%', md: '50%', lg: '33.33%' }} p="24px" h="300px">
              <Box
                position="relative"
                border="1px solid #DFDFDF"
                borderRadius="4px"
                pt="77px"
                px="24px"
                pb="16px"
                h="100%"
              >
                <Text textAlign="left" fontWeight="800" fontSize="30px" lineHeight="116%" mb="8px">
                  {card.title}
                </Text>
                <Text textAlign="left" fontWeight="400" fontSize="16px" lineHeight="146%" textColor="#505050">
                  {card.subtitle}
                </Text>
                <Flex
                  alignItems="center"
                  justifyContent="center"
                  position="absolute"
                  top="-19px"
                  left="24px"
                  w="80px"
                  h="80px"
                  bg={card.imageBackground}
                >
                  <Image display="block" src={card.image} w="40px" h="40px" alt={card.alt} />
                </Flex>
              </Box>
            </Box>
          ))}
          <Box w={{ base: '100%', md: '50%', lg: '33.33%' }} h={{ base: 'auto', md: '300px' }} p="24px">
            <Box
              h="100%"
              position="relative"
              bg={colorMode === 'dark' ? '#DFDFDF' : '#F7F7F7'}
              border="1px solid #DFDFDF"
              borderRadius="4px"
              py="24px"
            >
              <SignIn
                variant="link"
                text={`See all apps`}
                position={{ base: 'static', md: 'absolute' }}
                bottom="24px"
                right="29px"
                fontSize="16px"
                lineHeight="146%"
                fontWeight="500"
                bg="linear-gradient(108.17deg, #C57BFF 44.81%, #305DFF 100%);
                  -webkit-background-clip: text;
                  -webkit-text-fill-color: transparent;
                  background-clip: text;
                  text-fill-color: transparent;
                "
                mt="auto"
                px="0px"
              />
            </Box>
          </Box>
        </Flex>
      </Box>
    </Container>
  )
}

export default Apps
